import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { StatsDto } from '../../../features/info/stats/stats.component.servier';
import { HttpClientService } from '../../../http-client.service';

@Injectable({
  providedIn: 'root'
})
export class JobDetailsModalService extends HttpClientService {

  /**
   * 応募
   * @param inDto
   */
  application(inDto: StatsDto): Observable<any> {
    return super.httpPost('api/jobStats/add', inDto)
      .pipe(map((res) => {
        return res;
      }));
  }

  /** 一覧リスト取得 */
  search(inDto: InDto): Observable<any> {
    const httpParams = super.createHttpParamsByObject(inDto);
    return super.httpGet2<any>('api/jobInfo/details', httpParams)
      .pipe(map((res) => {
        return res;
      }));
  }

  /** 応募者リスト */
  searchList(inDto: InDto): Observable<any> {
    const httpParams = super.createHttpParamsByObject(inDto);
    return super.httpGet2<any>('api/jobStats/list', httpParams)
      .pipe(map((res) => {
        return res;
      }));
  }

  /** 案件削除API */
  delete(inDto: InDto): Observable<any> {
    return super.httpPost('api/jobInfo/delete', inDto)
      .pipe(map((res) => {
        return res;
      }));
  }

  /** ファイルダウンロード */
  getFile(inDto: any): Observable<any> {
    return super.downloadFile('api/jobInfo/print', inDto)
      .pipe(map((res) => {
        return res;
      }));
  }

}

/** 検索・削除DTO */
export class InDto {
  jobNo: string;
}

/** in dto */
export class ModalInDto {
  // no
  no: string;
  // 年収
  annualincome: string;
  // 会社名
  company_name: string;
  // 業界
  industry: string;
  // 仕事内容
  wrok_content: string;
  // 業種
  occupation: string;
  // 勤務地
  worklocation: string;
  // 年収下限
  lower_limit: number;
  // 年収上限
  upper_limit: number;
  // 年収補足
  income_repletion: string;
  // 福利厚生・待遇など
  welfare: string;
  // 雇用形態
  employment_status: string;
  // 募集人数
  rumberof_applicants: string;
  // 候補者への訴求ポイント
  point: string;
  // 事業内容
  business_content: string;
  // 海外選考
  residents_abroad: string;
  // tab
  tab: string;
  // 必須要件
  prerequisite: string;
  // 歓迎要件
  applicable_conditions: string;
  // 選考フロー
  selection_flow: string;
  // 選考時の注意点
  selection_point: string;
  // 採用背景
  adupt_background: string;
  // 備考
  remarks: string;
  // 更新日時
  updateDate: string;
}
