import { Router } from '@angular/router';
import { Component } from '@angular/core';

@Component({
  selector: 'smart-dropdown-apps',
  templateUrl: './dropdown-apps.component.html',
  styles: [`
    .dropdown-menus {
      position: absolute;
      border: 0px solid #ccc;
      right: 2rem;
      top: 4.0625rem !important;
      left: auto !important;
      padding: 0;
      margin: 0;
      z-index: 2000;
      display: none;
      float: left;
      min-width: 10rem;
      padding: 0.3125rem 0;
      margin: 0.125rem 0 0;
      font-size: 0.8125rem;
      color: #212529;
      text-align: left;
      list-style: none;
      background-color: #fff;
      background-clip: padding-box;
      border: 0px solid rgba(0, 0, 0, 0.15);
      border-radius: 4px;
    }
  `],
})
export class DropdownAppsComponent {
  role: string;
  constructor(private router: Router) {
    this.role = sessionStorage.getItem('SESSION_KEY_ROLE') || '0';
  }

  // 案件追加
  onClickJobAdd() {
    if (this.role > '2') {
      this.router.navigate(['/intel/case-add']);
    }
  }

  // 候補者一覧
  onClickViewPersonal() {
    if (this.role > '2') {
      this.router.navigate(['/intel/scouts']);
    }
  }

  // アカウント設定
  onClickAccount() {
    this.router.navigate(['/settings/account-setting']);
  }

  // 推薦一覧
  onClickStats() {
    // if (this.role > '2') {
      this.router.navigate(['/info/stats']);
    // }
  }

  // 対応中管理
  onClickDeals() {
    // if (this.role > '2') {
      this.router.navigate(['/intel/deals']);
    // }
  }
}
