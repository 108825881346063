import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClientService } from '../../../http-client.service';

@Injectable({
  providedIn: 'root'
})
export class StatsComponentService extends HttpClientService {

  /**
   * 保存処理を行う。
   * @param JobDto 案件追加DTO
   */
  registerInfo(inDto: StatsDto): Observable<StatsDto> {
    return super.httpPost('', inDto)
      .pipe(map((res) => {
        return res;
      }));
  }

  /** 一覧リスト取得 */
  search(inDto: any): Observable<StatsDto> {
    const httpParams = super.createHttpParamsByObject(inDto);
    return super.httpGet2<StatsDto>('api/jobStats', httpParams)
      .pipe(map((res) => {
        return res;
      }));
  }
}

export class StatsDto {
  // 提案
  no: number;
  // 星マーク(お気に入り)
  star_mark: boolean;
  // ステータス
  status: number;
  // 未読マーク
  unread: boolean;
  // 対象者
  target_person: string;
  // 対象者ID(応募者)
  target_person_id: string;
  // 求人企業名
  company_name: string;
  // 求人企業ID
  company_id: string;
  // 最新message
  newest_message: string;
  // 備考
  updateDate: string;
}
