import { Component, ChangeDetectionStrategy } from '@angular/core';
import { APP_CONFIG } from '../../../app.config';

@Component({
  selector: 'smart-logo',
  templateUrl: './logo.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoComponent {
  logo = APP_CONFIG.logo;
  appName = APP_CONFIG.appName;

  // constructor() { }

  // openShortcut($event: MouseEvent) {
  //   $event.preventDefault();
  //   // this.shortcut.openModal();

  // }

}
