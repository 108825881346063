import { Observer } from 'rxjs';
import { AppInjector } from './app.injector';
// import { ConfirmDialogService, } from './core/service/confirm-dialog.service';
import { MessgeToastService } from './core/service/messge-toast.service';

/**
 * 画面での共通処理を挿入するオブザーバーの雛形
 */
class ExObserver implements Observer<any> {
  private funcs: any = {};
  constructor(arg?: {
    next?: (value: any) => void;
    error?: (err: any) => void;
    complete?: () => void;
  }) {
    if (arg) {
      this.funcs.next = arg.next;
      this.funcs.error = arg.error;
      this.funcs.complete = arg.complete;
    }
  }
  next(value: any) {
    if (typeof this.funcs.next === 'function') {
      this.funcs.next(value);
    }
  }
  error(err: any) {
    if (typeof this.funcs.error === 'function') {
      this.funcs.error(err);
    }
  }
  complete() {
    if (typeof this.funcs.complete === 'function') {
      this.funcs.complete();
    }
  }
}
/**
 * コンポーネント基底クラス
 */
export abstract class ComponentBase {
  /* メッセージトーストサービス */
  protected messageToast: MessgeToastService;
  //   /* 確認ダイアログサービス */
  // protected confirmDialogService: ConfirmDialogService;

  /**
   * コンストラクタ
   */
  constructor() {
    const injector = AppInjector.getInjector();
    this.messageToast = injector.get(MessgeToastService);
    // this.confirmDialogService = injector.get(ConfirmDialogService);
    // clear message toast
    this.messageToast.clear();
  }
  /**
   * 登録用オブザーバーを取得する
   * @param arg コールバックメソッド
   */
  protected getRegisterObserver(
    arg?: {
      next?: (value: any) => void;
      error?: (err: any) => void;
      complete?: () => void;
    }
  ) {
    this.messageToast.clear();
    return new ExObserver({
      next: (value) => {
        if (arg && typeof arg.next === 'function') {
          arg.next(value);
        }
        this.messageToast.showSuccess('通信しました');
      },
      error: (err) => {
        if (arg && typeof arg.error === 'function') {
          arg.error(err);
        }
        if (err.error.message) {
          this.messageToast.showError(err.error.message);
          this.unAuthorized(err); // statusCode = 401
          return;
        }
        this.messageToast.showError('登録できませんでした');
      },
      complete: () => {
        if (arg && typeof arg.complete === 'function') {
          arg.complete();
        }
      }
    });
  }

  /**
   * 更新用オブザーバーを取得する
   * @param arg コールバックメソッド
   */
  protected getModifyObserver(
    arg?: {
      next?: (value: any) => void;
      error?: (err: any) => void;
      complete?: () => void;
    }
  ) {
    this.messageToast.clear();
    return new ExObserver({
      next: (value) => {
        if (arg && typeof arg.next === 'function') {
          arg.next(value);
        }
        this.messageToast.showSuccess('更新しました');
      },
      error: (err) => {
        if (arg && typeof arg.error === 'function') {
          arg.error(err);
        }
        if (err.error.message) {
          this.messageToast.showError(err.error.message);
          this.unAuthorized(err); // statusCode = 401
          return;
        }
        this.messageToast.showError('更新できませんでした');
      },
      complete: () => {
        if (arg && typeof arg.complete === 'function') {
          arg.complete();
        }
      }
    });
  }

  /**
   * 削除用オブザーバーを取得する
   * @param arg コールバックメソッド
   */
  protected getDeleteObserver(
    arg?: {
      next?: (value: any) => void;
      error?: (err: any) => void;
      complete?: () => void;
    }
  ) {
    this.messageToast.clear();
    return new ExObserver({
      next: (value) => {
        if (arg && typeof arg.next === 'function') {
          arg.next(value);
        }
        this.messageToast.showSuccess('削除しました');
      },
      error: (err) => {
        if (arg && typeof arg.error === 'function') {
          arg.error(err);
        }
        if (err.error.message) {
          this.messageToast.showError(err.error.message);
          this.unAuthorized(err); // statusCode = 401
          return;
        }
        this.messageToast.showError('削除できませんでした');
      },
      complete: () => {
        if (arg && typeof arg.complete === 'function') {
          arg.complete();
        }
      }
    });
  }

  /**
   * 更新と削除の同時実行用オブザーバーを取得する
   * @param arg コールバックメソッド
   */
  protected getModifyAndDeleteObserver(
    arg?: {
      next?: (value: any) => void;
      error?: (err: any) => void;
      complete?: () => void;
    }
  ) {
    this.messageToast.clear();
    return new ExObserver({
      next: (value) => {
        if (arg && typeof arg.next === 'function') {
          arg.next(value);
        }
      },
      error: (err) => {
        if (arg && typeof arg.error === 'function') {
          arg.error(err);
        }
        if (err.error.message) {
          this.messageToast.showError(err.error.message);
          this.unAuthorized(err); // statusCode = 401
          return;
        }
        this.messageToast.showError('登録できませんでした');
      },
      complete: () => {
        if (arg && typeof arg.complete === 'function') {
          arg.complete();
        }
      }
    });
  }

  /**
   * 取得用オブザーバーを取得する
   * @param arg コールバックメソッド
   */
  protected getFetchObserver(
    arg?: {
      next?: (value: any) => void;
      error?: (err: any) => void;
      complete?: () => void;
    }
  ) {
    this.messageToast.clear();
    return new ExObserver({
      next: (value) => {
        // TODO:フェッチ成功時の共通前処理の挿入
        if (arg && typeof arg.next === 'function') {
          // this.messageToast.showSuccess('取得できました');
          arg.next(value);
        }
      },
      error: (err) => {
        if (arg && typeof arg.error === 'function') {
          arg.error(err.error);
        }
        if (err.error.message) {
          this.messageToast.showError(err.error.message);
          this.unAuthorized(err); // statusCode = 401
          return;
        }
        this.messageToast.showError('取得できませんでした');
      },
      complete: () => {
        if (arg && typeof arg.complete === 'function') {
          arg.complete();
        }
      }
    });
  }

  /** 401 error event */
  unAuthorized(err) {
    if (err.status === 401) { // notfind token
      sessionStorage.clear();
      window.location.href = '/auth/login';
    }
  }
}
