import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ComponentBase } from '../../../component-base';
import { StatsDto } from '../../../features/info/stats/stats.component.servier';
import { JobDetailsModalService, InDto, ModalInDto } from './job-details-modal.service';
import { ChatDto, SupportService } from '../../../features/info/support-details-modal/support-details-modal.service';
import { thousands } from '../../../../common/tool';

@Component({
  selector: 'smart-details-modal',
  templateUrl: './job-details-modal.component.html',
  styleUrls: ['./job-details-modal.component.css']
})
export class JobDetailsModalComponent extends ComponentBase implements OnInit {

  inDto: ModalInDto = new ModalInDto();
  nmList = []; // 応募者リスト
  /** param */
  jobNo: string;
  dispalyNoList = []; // list
  // button display
  displayFlg = false;
  // 敏感フラグ
  sensitiveDisplayFlg = false;

  constructor(public bsModalRef: BsModalRef,
    public ref: ChangeDetectorRef,
    private router: Router,
    private detailsModalService: JobDetailsModalService,
    private supportService: SupportService,
  ) {
    super();
    if (Number(sessionStorage.getItem('SESSION_KEY_ROLE') || 0) >= 3) {
      this.displayFlg = true; // display
    }
    if (Number(sessionStorage.getItem('SESSION_KEY_ROLE') || 0) >= 2) {
      this.sensitiveDisplayFlg = true;
    }
  }

  ngOnInit(): void {
    const inDto = new InDto();
    inDto.jobNo = this.jobNo;
    // 検索
    const searchResult$ = this.detailsModalService.search(inDto);
    searchResult$.subscribe(this.getFetchObserver({
      next: (response: any) => {
        this.inDto = response.result.content[0];
        this.inDto.lower_limit = thousands(this.inDto.lower_limit);
        this.inDto.upper_limit = thousands(this.inDto.upper_limit);
      }
    }));

    // list dispaly
    if (this.displayFlg) {
      const request$ = this.detailsModalService.searchList(inDto);
      request$.subscribe(this.getFetchObserver({
        next: (response: any) => {
          this.nmList = response.result.content;
        }
      }));
    }
  }

  // モーダル閉じる
  closeShortcut() {
    this.bsModalRef.hide();
  }

  // 応募
  onClickPetition() {
    // エントリー済みなのかを検証する
    if (confirm('案件に応募しますか?')) {
      if (!sessionStorage.getItem('SESSION_KEY_AUTH_TOKEN')) {
        alert('応募者情報をエントリーしてください');
        this.closeShortcut(); // close
        this.router.navigate(['/intel/privacy']);
        return;
      }
        const outDto = new StatsDto();
        outDto.company_id = this.inDto.no;
        outDto.company_name = this.inDto.company_name;
        outDto.status = 2; // 推薦中^
        outDto.star_mark = false; // TODO buttton
        outDto.target_person_id = sessionStorage.getItem('SESSION_KEY_USER_ID') || ''; // get session
        outDto.target_person = sessionStorage.getItem('SESSION_KEY_MAILE') || '';
        outDto.newest_message = ''; // todo
        const searchResult$ = this.detailsModalService.application(outDto);
        searchResult$.subscribe(this.getRegisterObserver({
          next: (r) => {
            const dto = new ChatDto();
            dto.caseId = r.result.no;
            dto.content = '（システムメッセージ） 応募受信中';
            dto.receiverId = '延喜Staff';
            dto.senderId = sessionStorage.getItem('SESSION_KEY_USER_ID') || '';
            // send
            const searchResult$ = this.supportService.addMessage(dto);
            searchResult$.subscribe(this.getRegisterObserver({
              next: (r) => {
              }
            }));
            this.messageToast.showInfo('弊社からのご連絡をお待ちください');
          },
          error: (err) => {
            if (err.status == 403) {
              this.closeShortcut(); // close
              this.router.navigate(['/intel/privacy']);
            }
          }
        }));
    }
  }

  /** 案件質問 */
  onClickQuery() {
    this.closeShortcut(); // close
    this.router.navigate(['/info/support']);
  }

  /** 案件クローズ */
  onClickJobClose() {
    if (confirm('案件をクローズしますか?')) {
      const inDto = new InDto();
      inDto.jobNo = this.jobNo;
      const searchResult$ = this.detailsModalService.delete(inDto);
      searchResult$.subscribe(this.getDeleteObserver({
        next: () => {
          this.router.navigate(['/intel/job-info']);
          this.closeShortcut(); // close
        }
      }));
    }
  }

  /** 前へ */
  onClickForward() {
    const index = this.dispalyNoList.indexOf(this.inDto.no);
    if (index > 0) {
      this.jobNo = this.dispalyNoList[index - 1];
      this.ngOnInit();
    }
  }

  /** 次へ */
  onClickNext() {
    const index = this.dispalyNoList.indexOf(this.inDto.no);
    if (index > -1 && index < (this.dispalyNoList.length - 1)) {
      this.jobNo = this.dispalyNoList[index + 1];
      this.ngOnInit();
    }
  }

  /** 編集 */
  onClickJobEdit() {
    const dto = { jobNo: this.inDto.no };
    this.router.navigate(['/intel/case-add', dto]);
    this.closeShortcut();
  }

  /** PDF印刷 */
  onClickPrint() {
    localStorage.setItem('view', JSON.stringify(this.inDto));
    window.open(window.location.origin + '/public/pdf-view', )
  }

}
