import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';


@Injectable({
    providedIn: 'root'
})
export class MessgeToastService {
    constructor(private messageService: MessageService) { }

    /** 成功メッセージ */
    showSuccess(message: string) {
        this.messageService.add({ severity: 'success', summary: 'メッセージ', detail: message, life: 1000 });
    }

    /** 通知メッセージ */
    showInfo(message: string) {
        this.messageService.add({ severity: 'info', summary: 'メッセージ', detail: message, life: 6000 });
    }

    /** coming soon */
    showComing() {
        this.messageService.add({ severity: 'info', summary: 'メッセージ', detail: '近日公開.', life: 4000 });
    }

    /** 警告メッセージ */
    showWarn(message: string) {
        this.messageService.add({ severity: 'warn', summary: 'メッセージ', detail: message, life: 5000 });
    }

    /** エラーメッセージ */
    showError(message: string) {
        this.messageService.add({ severity: 'error', summary: 'メッセージ', detail: message, life: 8000 });
    }

    /** 確認モーダル */
    showConfirm() {
        this.messageService.clear();
        this.messageService.add({ key: 'c', sticky: true, severity: 'warn', summary: 'Are you sure?', detail: 'Confirm to proceed' });
    }
    onConfirm() {
        this.messageService.clear('c');
    }

    clear() {
        this.messageService.clear();
    }
}
