import { ChangeDetectorRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ComponentBase } from '../../component-base';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent extends ComponentBase implements OnInit {
  loginForm: FormGroup;
  submitted = false;

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  constructor(private fb: FormBuilder, private router: Router, private authService: AuthService,
    private ref: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    if (sessionStorage.getItem('SESSION_KEY_AUTH_TOKEN')) {
      this.router.navigate(['/intel/job-info']);
      return;
    }
    // session clean up
    sessionStorage.clear();
    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      remember: [true]
    });
    this.messageToast.showInfo('登録後、すべての求人案件にアクセスできます。費用は一切かかりません。');
  }

  logout() {
    this.messageToast.showSuccess('積極的なご応募をお待ちしております。');
    sessionStorage.clear();
  }

  onClickSingUp() {
    this.router.navigate(['/auth/sign-up']);
  }

  onSubmit(): void {
    this.submitted = true;
    this.ref.markForCheck();	// Renewal
    this.ref.detectChanges();
    // stop here if form is invalid
    if (this.loginForm.invalid) { return; }
    if (Number(sessionStorage.getItem('LOCK_COUNT') || 0) >= 8) {
      this.messageToast.showError('パスワードの誤り回数が足した為、10分間をお待ち下さい');
      return;
    }
    this.authService.login(this.loginForm.value.email, this.loginForm.value.password)
      .pipe(first())
      .subscribe(this.getFetchObserver({
        next: (responseDTO: any) => {
          this.messageToast.showSuccess('多彩な求人情報が待っています。あなたの理想の仕事がきっと見つかります。');
          const content = responseDTO.result.content;
          sessionStorage.setItem('SESSION_KEY_AUTH_TOKEN', content.token);
          sessionStorage.setItem('SESSION_KEY_MAILE', content.username);
          sessionStorage.setItem('SESSION_KEY_USER_ID', content.no);
          sessionStorage.setItem('SESSION_KEY_ROLE', content.authority);
          this.router.navigate(['/intel/as']);
        }
      }));
  }
}
