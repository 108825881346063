import { Component } from '@angular/core';

@Component({
  selector: 'smart-root',
  template: `<router-outlet></router-outlet>
  <p-toast [style]="{marginTop: '80px'}" [baseZIndex]="5000"></p-toast>`
})
export class AppComponent {
  title = '延喜求人NET';
}
