export const NavigationItems = [
  {
    title: 'アクションする'
  },
  {
    title: '求人情報',
    tags: 'application intel',
    icon: 'fal fa-address-card',
    items: [
      {
        title: '求人検索',
        tags: 'application intel introduction',
        routerLink: '/intel/as'
      },
      {
        title: '求人一覧',
        tags: 'application intel introduction',
        routerLink: '/intel/job-info'
      },
      // {
      //   title: 'マッチング',
      //   tags: 'application intel privacy',
      //   routerLink: '/intel/match'
      // }
    ]
  },
  {
    title: '候補者プロフィール',
    tags: 'application intel',
    icon: 'fal fa-address-card',
    items: [
      {
        title: 'プロフィール',
        tags: 'application intel privacy',
        routerLink: '/intel/privacy'
      },
    ]
  },
  {
    title: 'お問い合わせ',
    tags: 'お問い合わせ',
    icon: 'fal fa-briefcase',
    items: [
      {
        title: '延喜について',
        tags: 'package info product licensing',
        routerLink: '/info/about-us'
      },
      {
        title: 'サポート',
        tags: 'package info product licensin',
        routerLink: '/info/support'
      },
    ]
  },
  {
    title: 'その他'
  },
  {
    title: '設定',
    tags: 'settings',
    icon: 'fal fa-cog',
    items: [
      {
        title: 'アカウント設定',
        tags: 'theme settings how it works',
        routerLink: '/settings/account-setting'
      },
    ]
  }
];
