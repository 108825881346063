import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { toggleFilter } from '../../../store/navigation';
import { APP_CONFIG } from '../../../app.config';

@Component({
  selector: 'smart-nav-info-card',
  templateUrl: './nav-info-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavInfoCardComponent {
  /** uesr infomation(view) */
  user = sessionStorage.getItem('SESSION_KEY_MAILE') || '';
  avatar = APP_CONFIG.avatar;

  constructor(private store: Store<any>) { }

  toggleFilter($event: MouseEvent) {
    $event.preventDefault();
    this.store.dispatch(toggleFilter());

  }
}
