export const APP_CONFIG = {
  app: 'ENGI_JOB',
  appName: '  延喜求人NET',
  email: sessionStorage.getItem('SESSION_KEY_MAILE') || '',
  name: sessionStorage.getItem('SESSION_KEY_USER_ID') || '',
  role: sessionStorage.getItem('SESSION_KEY_ROLE') || 0,
  avatar: 'avatar-admin.png',
  version: '1.0.0',
  logo: 'logo.png',
  logoM: 'logo.png',
  // tslint:disable-next-line:max-line-length
  copyright: '2023 © 延喜求人NET &nbsp; ものづくり補助金により作成 &nbsp;<a href="https://www.exky.jp/" class="text-primary fw-500" title="延喜ホームページ" target="_blank">延喜</a>',
};
