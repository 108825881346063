import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { mobileNavigation } from 'src/app/store/navigation';
import { APP_CONFIG } from '../../../app.config';
import { Router } from '@angular/router';

@Component({
  selector: 'smart-page-header',
  templateUrl: './page-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [''],
})
export class PageHeaderComponent {

  avatar = APP_CONFIG.avatar;
  email = APP_CONFIG.email;
  user = APP_CONFIG.email;

  constructor(private store: Store<any>,
    private router: Router,
  ) { }

  openMobileNav($event: MouseEvent) {
    $event.preventDefault();
    this.store.dispatch(mobileNavigation({open: true}));
  }

  // 対応中
  onClickStats() {
    this.router.navigate(['/info/stats']);
  }

}
