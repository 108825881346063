// CommonTools

/** isEmpty */
export function isEmpty(obj: any) {
  return typeof obj === 'undefined' || obj == null || obj === '';
}
  
/** isNotEmpty */
export function isNotEmpty(item: string) {
  return item && item.trim() !== '';
}

/** カンマ加え */
export function thousands(num: any) {
  if (!num) { return ''; }
  return num.toLocaleString();
}

/** content null check
 * If one is not null　*return: ture
 */
export function DtoNullCheck(dto: any): boolean {
  for (const item in dto) {
    if (dto[item] !== null && dto[item] !== '') {
      return true;
    }
  }
  return false;
}