import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import qs from 'qs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  /** ログイン */
  login(mail: string, password: string): Observable<string> {
    const options = { mail, password };
    return this.http.post<any>('api/user/login', qs.stringify(options), httpOptions)
      .pipe(
        catchError(this.handleError('login'))
      );
  }

  /** 登録 */
  signUp(mail: string, password: string): Observable<string> {
    const options = { mail, password };
    return this.http.post<any>('api/user/register', qs.stringify(options), httpOptions)
      .pipe(
        catchError(this.handleError())
      );
  }

  private handleError<T>(v?: string) {
    return (error: any): Observable<T> => {
      console.error(error);
      if (v && !sessionStorage.getItem('LOCK_COUNT')) {
        sessionStorage.setItem('LOCK_COUNT', '1');
        return throwError(error);
      }
      // lock account
      const count = (Number(sessionStorage.getItem('LOCK_COUNT') || 0) + 1);
      sessionStorage.setItem('LOCK_COUNT', count.toString());
      return throwError(error);
    };
  }
}
