import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ComponentBase } from '../../component-base';
import { AuthService } from '../auth.service';

@Component({
  selector: 'sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent extends ComponentBase implements OnInit {
  loginForm: FormGroup;
  submitted = false;

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  constructor(private fb: FormBuilder, private router: Router, private authService: AuthService) {
    super();
  }

  ngOnInit(): void {
    // session clean up
    sessionStorage.clear();
    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      repassword: ['', Validators.required],
      remember: [true]
    });
  }

  goBack() {
    this.router.navigate(['/auth/login']);
  }

  onSubmit(): void {
    this.submitted = true;
    this.messageToast.clear();
    // stop here if form is invalid
    if (this.loginForm.invalid) { return; }
    if (this.f.password.value.length < 7 || this.f.password.value.length > 10) {
      this.messageToast.showWarn('パスワードを7桁以上10桁以下を入力してください');
      return;
    }
    if (this.f.password.value !== this.f.repassword.value) {
      this.messageToast.showWarn('パスワードと確認パスワードが一致しない');
      return;
    }
    if (this.isAvailableEmail(this.f.email.value)) {
      this.messageToast.showError('正しいメールを入力して下さい');
      return;
    }
    this.authService.signUp(this.loginForm.value.email, this.loginForm.value.password)
      .pipe(first())
      .subscribe(this.getFetchObserver({
        next: () => {
          this.messageToast.showSuccess('多彩な求人情報が待っています。あなたの理想の仕事がきっと見つかります。');
          this.router.navigate(['/intel/job-info']);
        }
      }));
  }

  isAvailableEmail(sEmail: string) {
    const reg = /^([\w+\.])+@\w+([.]\w+)+$/;
    return !reg.test(sEmail);
  }
}
