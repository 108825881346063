import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ComponentBase } from '../../../component-base';

@Component({
  selector: 'smart-dropdown-user',
  templateUrl: './dropdown-user.component.html',
})
export class DropdownUserComponent extends ComponentBase {

  constructor(private router: Router) {
    super();
  }
  user = {
    app: 'EngiJob',
    name: sessionStorage.getItem('SESSION_KEY_MAILE') || '',
    email: sessionStorage.getItem('SESSION_KEY_MAILE') || '',
    avatar: 'avatar-admin.png',
  };

   /** ログアウト */
   logout() {
    this.messageToast.showInfo('ログアウトしました。');
    sessionStorage.clear();
    this.router.navigateByUrl('/auth/login');
  }

}
