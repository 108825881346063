import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable()
export class HttpClientService {

  /**
   * コンストラクタ. HttpClientService のインスタンスを生成する
   */
  constructor(private http: HttpClient) {
  }

  /**
   * Authorizatino に認証トークンを設定する
   * @param {string} token 認証トークン
   */
  public setAuthorization(): string {
    return `Bearer ${sessionStorage.getItem('SESSION_KEY_AUTH_TOKEN') || ''}`;
  }
  private getUserId() {
    return sessionStorage.getItem('SESSION_KEY_SESSION_KEY_USER_ID') || '';
  }

  /**
   * inDtoから[{key:prop, value:value}]のクエリパスを生成
   * @param inDto
   */
  public createHttpParamsByObject(inDto: any): HttpParams {
    const pList: { key: string; value: string }[] = [];
    Object.keys(inDto).forEach((key) => {
      pList.push({ key: key, value: inDto[key] });
    });
    return this.createHttpParams(pList);
  }
  /**
   * URLに設定するクエリパスを生成する
   * @param queries クエリパラメータ
   * @return クエリパス
   */
  protected createHttpParams(queries: Array<{ key: string; value: any }>): HttpParams {
    let params = new HttpParams();
    queries.forEach(
      query => {
        params = params.append(query.key, query.value);
      }
    );
    return params;
  }

  /**
   * POST要求を実行する
   * @param path パス
   * @param createObj 作成するリソース
   */
  protected httpPost(path: string, createObj: any): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=UTF-8'
        , "X-Requested-By": this.setAuthorization()
        , "Uid": this.getUserId()
      }),
      withCredentials: true
    };
    const x = this.http.post(path, createObj, options);
    return x;
  }

  /**
   * GET file要求を実行する
   * @param path パス
   * @param params パラメータ
   */
  downloadFile(path: string, params: any): Observable<Blob> {
    return this.http.post(path, params, {
      headers: new HttpHeaders({ 'X-Requested-By': this.setAuthorization() }),
      withCredentials: true,
      responseType: 'blob'
    });
  }

  /**
   * GET要求を実行する
   * @param path パス
   * @param params パラメータ
   */
  protected httpGet2<T>(path: string, params: HttpParams): Observable<any> {
    const options = {
      params,
      withCredentials: true,
      headers: new HttpHeaders({ "X-Requested-By": this.setAuthorization() })
    };
    return this.http.get<any>(path, options);
  }

  /**
   * POST要求を実行する
   * @param path パス
   * @param createObj 作成するリソース
   */
  protected httpPostUpload(path: string, createObj: File): Observable<any> {
    let formDataToSend = new FormData();

    if (createObj instanceof FormData) {
      formDataToSend = createObj;
    } else {
      formDataToSend.append('file', createObj);
    }
    const options = {
      withCredentials: true,
      headers: new HttpHeaders({ "X-Requested-By": this.setAuthorization() })
    };
    return this.http.post(path, formDataToSend, options);
    // return this.http.post(path, formDataToSend);

  }

  /**
   * PUT要求を実行する
   * @param path パス
   * @param updateObj 更新するリソース
   */
  protected httpPut(path: string, updateObj: any): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
        , "X-Requested-By": this.setAuthorization()
      }),
      withCredentials: true
    };
    Object.keys(updateObj).forEach((key: string) => {
      const value = updateObj[key];
      if (value === undefined) {
        updateObj[key] = '';
      }
    });
    const x = this.http.put(path, updateObj, options);
    return x;
  }

}
