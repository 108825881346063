import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClientService } from '../../../http-client.service'

@Injectable({
  providedIn: 'root'
})
export class SupportService extends HttpClientService {

  /** 個人詳細取得 */
  getPersonalInfo(inDto: { userId: string }): Observable<HistoryInfoDto> {
    const httpParams = super.createHttpParamsByObject(inDto);
    return super.httpGet2<HistoryInfoDto>('api/personalInfo', httpParams)
      .pipe(map((res) => {
        return res;
      }));
  }

  /** ケース詳細取得 */
  getJobCase(inDto: any): Observable<CaseInfoDto> {
    const httpParams = super.createHttpParamsByObject(inDto);
    return super.httpGet2<any>('api/jobInfo/details', httpParams)
      .pipe(map((res) => {
        return res;
      }));
  }

  /** ファイルダウンロード */
  getFile(inDto: any): Observable<CaseInfoDto> {
    return super.httpPost('api/file/download', inDto)
      .pipe(map((res) => {
        return res;
      }));
  }
  
  /** チャット */
  getMessage(inDto: any): Observable<CaseInfoDto> {
    const httpParams = super.createHttpParamsByObject(inDto);
    return super.httpGet2<any>('api/messages/case', httpParams)
      .pipe(map((res) => {
        return res;
      }));
  }

    /**
   * 応募
   * @param inDto
   */
  addMessage(inDto: ChatDto): Observable<any> {
    return super.httpPost('api/messages', inDto)
      .pipe(map((res) => {
        return res;
      }));
  }

  /**
   * 案件ステータス変更
   * @param JobDto 案件追加DTO
   */
  statusEdit(inDto: Status): Observable<any> {
    return super.httpPost('api/jobStats/edit', inDto)
      .pipe(map((res) => {
        return res;
      }));
  }

  /**
   * ステータス
   */
  applicantStatus(status: number): string {
    switch (status) {
      case ApplicantStatus.Scout:
        return 'スカウト';
      case ApplicantStatus.ScoutAccepted:
        return 'スカウト承諾';
      case ApplicantStatus.ScoutRejected:
        return 'スカウト辞退';
      case ApplicantStatus.Recommended:
        return '推薦中';
      case ApplicantStatus.RecommendationRejected:
        return '推薦辞退';
      case ApplicantStatus.FirstInterview:
        return '一次面接';
      case ApplicantStatus.SecondInterview:
        return '二次面接';
      case ApplicantStatus.OfferPending:
        return '入社待ち';
      case ApplicantStatus.Hired:
        return '入社済み';
      default:
        return ''
    }
  }
}

/** チャットDTO */
export class ChatDto {
  // 親ID
  caseId: number;
  // 内容
  content: string;
  // 発信者
  senderId: string;
  // 着信者
  receiverId: string;
  // その他
  others: string;
}

/** 職歴DTO */
export class HistoryInfoDto {

  userId: string;　// one(indexNo) to many(id)
  detailsNo: number; // 明細番号
  job_company: string;　// 会社名
  job_first_year_past: string;　// 入社年
  job_first_month: string;　// 月
  job_last_year_past: string; // 退社年
  job_last_month: string;　// 月
  job_now: boolean; // 在籍中
  job_department: string; // 部署
  job_position: string;　// 肩書
  job_content: string;　//  職務内容
  job_type: string;　// 雇用形態
  job_typename: string; // 職種
  deleteFlag?: boolean; // 削除フラグ
  updateDate: Date; // 更新日時
}

/** 学歴DTO */
export class EducationInfoDto {

  userId: string;　// one(indexNo) to many(id)
  detailsNo: number; // 明細番号
  education_name: string; // 学校名
  education_kind: string; // 学校種別
  education_first_year: string; // 入学年
  education_first_month: string; // 入学月
  education_last_year_center: string; // 卒業(予定)年
  education_last_month: string; // 卒業(予定)月
  education_major: string; // 学部/学科
  deleteFlag: boolean; // 削除フラグ
  updateDate: Date; // 更新日時
}

/** 資格DTO */
export class QualificationDto {

  detailsNo: number; // 明細番号
  userId: string;　// one(indexNo) to many(id)
  qualification_name: string; // 資格名
  qualification_year: number; // 取得年
  qualification_month: number; // 取得月
  updateDate: Date; // 更新日時
}

/** 個人情報DTO */
export class PersonalInfoDto {

  userId: string; // NO:主キー
  name: string;
  email: string;
  phone: string;
  wechat: string;
  // 誕生日
  birth_year: number;
  birth_month: number;
  birth_day: number;
  /** 国籍 */
  nationality: string;
  /** 性別 */
  gender: string;
  /** 在住地 */
  resident: string;
  /** 国内都道府県 */
  prefectureof_residence: string;
  /** 在住国 */
  couofResidence: string;
  /** japannese level */
  japaneselevel: string;
  /** 日本語能力試験 */
  jlpt_testLevel: string;
  jlpt_testDate: string;
  /** 在留資格 */
  status_residencetype: string;
  /** 在留資格期限 */
  status_residence_Year: string;
  status_residence_month: string;
  status_residence_day: string;
  /** 英語レベル */
  english_level: string;
  /** chinese label */
  chinese_label: string;
  /** skill soft */
  skill_soft: string;
  /** プログラミング言語 */
  programminglan: string;
  programming_years: string;
  /** 文理 */
  literature: string;
  /** 現在の職業 */
  current_occupation: string;
  /** 職務要約 */
  jobsummary: string;
    /** resume（PDF） */
  resume: string;
  resume_key: string;
  /** 職務経歴書（PDF） */
  curriculumvitae: string;
  curriculumvitae_key: string;
  /** 現在年収 */
  recentincome: number;
  /** 希望年収 */
  desiredsalary: number;
  /** 転職理由 */
  reason: string;
  /** 本人希望 */
  hope: string;
  updateDate: Date; // 更新日時
}

/** リクエストDTO */
export class DetailsDto {
  // 個人情報
  personal: PersonalInfoDto;
  // 資格
  qualification: QualificationDto;
  // 学歴
  education: EducationInfoDto;
  // 職歴
  history: HistoryInfoDto;
}

/** 案件情報  */
export class CaseInfoDto {

  no: number; // NO
  manage_title: string; // 管理タイトル {{ jobCaseList. }}
  company_name: string; // 会社名
  industry: string; // 業界
  wrok_content: string; // 仕事内容
  occupation: string; // 業種
  business_content: string; // 事業内容
  worklocation: string;　// 勤務地
  lower_limit: string;　// 年収下限
  upper_limit: string;　// 年収上限
  income_repletion: string; // 年収補足
  welfare: string; // 福利厚生・待遇など
  employment_status: string; // 雇用形態
  rumberof_applicants: string; // 募集人数
  residents_abroad: string; // 海外選考
  point: string; // 候補者への訴求ポイント
  tab: string; // tab
  prerequisite: string; // 必須要件
  applicable_conditions: string; // 歓迎要件
  selection_flow: string; // 選考フロー
  selection_point: string; // 選考時の注意点
  adupt_background: string; // 採用背景
  remarks: string; // 備考
  offerAmount: string; // 提示額
  updateDate: Date; // 更新日時
}

export class DownloadFileDto {
  userId: string; // 個人番号
  fileName: string; // ファイル名
  fileKey: string; // s3 key
  updateDate: string | Date;
}

/** ステータス */
export class Status {
  no: number;
  // ステータス　（1スカウト 2スカウト承諾 3スカウト辞退　4推薦中 5推薦辞退　6一次面接　7二次面接　8入社待ち　9入社済み）
  status: number;
}

// ステータス　（1スカウト 2スカウト承諾 3スカウト辞退　4推薦中 5推薦辞退　6一次面接　7二次面接　8入社待ち　9入社済み）
export enum ApplicantStatus {
  Scout = 1,
  ScoutAccepted = 2,
  ScoutRejected = 3,
  Recommended = 4,
  RecommendationRejected = 5,
  FirstInterview = 6,
  SecondInterview = 7,
  OfferPending = 8,
  Hired = 9,
}